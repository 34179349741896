import React from "react";
import { useTranslation } from "react-i18next";
import { useDataContext } from "../Context/DataContext";
import arrowleft from "../Images/arrowleft.png";
import YouTube from "react-youtube";

function ClientAppGeneric({ guideTypeTitle, onBack, guideType }) {
  const { t, i18n } = useTranslation();
  const { propertyData, setPropertyData } = useDataContext();

  //const baseURL = "https://private.mybusiness-bnb.fr/Guide/";
  const baseURL = "https://mybusiness-bnb.fr/Guide/";
  //const baseURL = "http://localhost:65447/api/";

  const transformLinks = (text) => {
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    return text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: white;">${url}</a>`
    );
  };

  const filteredData = propertyData.data.guideDetails
    .filter((item) => item.guideType == guideType)
    .sort((a, b) => a.order - b.order);

  const getVideoIdFromUrl = (url) => {
    const shortVideoIdMatch = url.match(/youtube\.com\/shorts\/([^\n?#]+)/);
    const standardVideoIdMatch = url.match(
      /(?:\?v=|&v=|youtu\.be\/)([^&\n?#]+)/
    );

    if (shortVideoIdMatch && shortVideoIdMatch[1]) {
      return shortVideoIdMatch[1];
    } else if (standardVideoIdMatch && standardVideoIdMatch[1]) {
      return standardVideoIdMatch[1];
    } else {
      // Gérer le cas où l'URL ne contient pas d'identifiant de vidéo valide
      return "";
    }
  };

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {" "}
        {/* Aligner les éléments vers la gauche */}
        <div
          onClick={onBack}
          style={{ alignSelf: "flex-start", paddingTop: "5px" }}
        >
          <img
            src={arrowleft}
            alt="Back"
            style={{ width: "32px", height: "32px" }}
          />
        </div>
        <h4 style={{ color: "white", marginLeft: "auto", marginRight: "auto" }}>
          {guideTypeTitle}
        </h4>
      </div>
      <br />
      <div>
        {/* Boucle à travers les données filtrées et triées */}
        {filteredData.map((item, index) => (
          <div key={item.id}>
            {item.itemType === 1 ? (
              <p
                style={{
                  background: "#2980B9",
                  padding: "10px",
                  borderRadius: "5px",
                  textAlign: "left",
                  margin: "5px 0",
                }}
              >
                {item.item.split(/(\*.*?\*)/).map((textChunk, index) => (
                  <React.Fragment key={index}>
                    {textChunk.startsWith("*") && textChunk.endsWith("*") ? (
                      <b>{textChunk.slice(1, -1)}</b>
                    ) : (
                      textChunk.split(/(\n)/).map((chunk, index) => (
                        <React.Fragment key={index}>
                          {chunk === "\n" ? (
                            <br />
                          ) : (
                            <span
                              style={{ color: "white" }}
                              dangerouslySetInnerHTML={{
                                __html: transformLinks(chunk),
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))
                    )}
                  </React.Fragment>
                ))}
              </p> // Afficher les éléments de type 1
            ) : item.itemType === 2 ? (
              <img
                src={baseURL + item.item}
                alt="Image"
                style={{ width: "100%", height: "auto", borderRadius: "10px" }}
              /> // Afficher les éléments de type 2 (supposant que item.item contient l'URL de l'image)
            ) : item.itemType === 3 ? (
              <div key={item.id}>
                {item.itemType === 3 ? (
                  <div
                    key={`video-${index}`}
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: "0",
                    }}
                  >
                    <YouTube
                      videoId={getVideoIdFromUrl(item.item)}
                      opts={{
                        width: "100%",
                        height: "100%",
                        playerVars: { autoplay: 0 },
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientAppGeneric;
