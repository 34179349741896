import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDataContext } from "../Context/DataContext";
import wifiNetworkNameI from "../Images/wifiNetworkName.png";
import wifipwd from "../Images/wifipwd.png";
import QRCode from "react-qr-code";
import arrowleft from "../Images/arrowleft.png";

function ClientAppWifi({ guideTypeTitle, onBack }) {
  const { t, i18n } = useTranslation();
  const { propertyData, setPropertyData } = useDataContext();
  const [linkCopied, setLinkCopied] = useState("Copier le mot de passe");
  const [linkCopiedColor, setLinkCopiedColor] = useState(false);
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256);

  if (propertyData.data.apartmentDetails == null) {
    return (
      <div className="mt-4">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {" "}
          {/* Aligner les éléments vers la gauche */}
          <div
            onClick={onBack}
            style={{ alignSelf: "flex-start", paddingTop: "5px" }}
          >
            <img
              src={arrowleft}
              alt="Back"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
          <h4
            style={{
              color: "white",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {guideTypeTitle}
          </h4>
        </div>
      </div>
    );
  }

  const linkToCopy = propertyData.data.apartmentDetails.wifiPassword;
  const wifiNetworkName = propertyData.data.apartmentDetails.wifiNetworkName;
  const wifiPassword = propertyData.data.apartmentDetails.wifiPassword;

  const wifiData = `WIFI:T:WPA;S:${wifiNetworkName};P:${wifiPassword};;`;

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = linkToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setLinkCopied("Mot de passe copié !");
      setLinkCopiedColor(true);

      setTimeout(() => {
        setLinkCopied("Copier le mot de passe");
        setLinkCopiedColor(false);
      }, 2000);
    } catch (err) {
      console.error("Erreur lors de la copie dans le presse-papiers : ", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  const handlePhoneCall = () => {
    window.location.href = `tel:${propertyData.data.apartmentDetails.personToContactPhone}`;
  };

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {" "}
        {/* Aligner les éléments vers la gauche */}
        <div
          onClick={onBack}
          style={{ alignSelf: "flex-start", paddingTop: "5px" }}
        >
          <img
            src={arrowleft}
            alt="Back"
            style={{ width: "32px", height: "32px" }}
          />
        </div>
        <h4 style={{ color: "white", marginLeft: "auto", marginRight: "auto" }}>
          {guideTypeTitle}
        </h4>
      </div>
      <br />
      <div>
        <div className="mb-3">
          <div
            style={{
              background: "#515151",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <img
              src={wifiNetworkNameI}
              alt=""
              style={{ width: "32px", height: "32px" }}
            />
            <span style={{ marginLeft: "10px" }}>
              {propertyData.data.apartmentDetails.wifiNetworkName}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-3">
          <div
            style={{
              background: "#515151",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <img
              src={wifipwd}
              alt=""
              style={{ width: "32px", height: "32px" }}
            />
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              {propertyData.data.apartmentDetails.wifiPassword}
            </span>
          </div>
          <div
            style={{
              background: "#515151",
              padding: "10px",
              borderRadius: "5px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={copyToClipboard}
          >
            <span
              style={{
                display: "flex",
              }}
            >
              <svg
                width="32px"
                height="32px"
                viewBox="-0.25 -0.25 25.50 25.50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="SVGRepo_bgCarrier"
                  strokeWidth="0"
                  transform="translate(0,0), scale(1)"
                >
                  <rect
                    x="-0.25"
                    y="-0.25"
                    width="25.50"
                    height="25.50"
                    rx="6.12"
                    fill="#2e9e6a"
                    strokeWidth="0"
                  ></rect>
                </g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#CCCCCC"
                  strokeWidth="0.9"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.676 14.248C17.676 15.8651 16.3651 17.176 14.748 17.176H7.428C5.81091 17.176 4.5 15.8651 4.5 14.248V6.928C4.5 5.31091 5.81091 4 7.428 4H14.748C16.3651 4 17.676 5.31091 17.676 6.928V14.248Z"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M10.252 20H17.572C19.1891 20 20.5 18.689 20.5 17.072V9.75195"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </svg>
              &nbsp;&nbsp;
              {linkCopied}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-3">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "black",
              padding: "10px",
              borderRadius: "10px",
              width: "100%", // Fond qui s'adapte à la largeur de l'écran
            }}
          >
            <QRCode
              title="Wifi QR Code"
              value={wifiData}
              bgColor={back}
              fgColor={fore}
              size={size === "" ? 0 : size}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAppWifi;
