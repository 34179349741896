import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import france from "../Images/france.png";
import germany from "../Images/germany.png";
import uk from "../Images/uk.png";
import spain from "../Images/spain.png";

function Languages({ onContinue, onOptionChange }) {
  //const storedOption = localStorage.getItem("selectedOption") || "Français";
  const [storedOption, setStoredOption] = useState("Français");
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(storedOption);
  const [selectedCountry, setSelectedCountry] = useState("fr");

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    i18n.changeLanguage(country);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    //localStorage.setItem("selectedOption", option);
    setStoredOption(option);
    onOptionChange(option); // Appeler la fonction de rappel
  };

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4>{t("chooseLanguage")}</h4>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateRows: "repeat(2, 1fr)",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "65px",
            margin: "30px",
          }}
        >
          <div>
            <img
              src={france}
              alt=""
              className={`flag-image ${
                selectedCountry === "fr" ? "selected" : ""
              }`}
              style={{
                width: "95px",
                height: "50px",
                borderRadius: "10%",
                boxShadow:
                  selectedCountry === "fr"
                    ? "0 0 20px rgba(255, 193, 7, 1)"
                    : "none",
              }}
              onClick={() => handleCountryChange("fr")}
            />
          </div>

          <div>
            <img
              src={uk}
              alt=""
              className={`flag-image ${
                selectedCountry === "gb" ? "selected" : ""
              }`}
              style={{
                width: "95px",
                height: "50px",
                borderRadius: "10%",
                boxShadow:
                  selectedCountry === "gb"
                    ? "0 0 20px rgba(255, 193, 7, 1)"
                    : "none",
              }}
              onClick={() => handleCountryChange("gb")}
            ></img>
          </div>
          <div>
            <img
              src={spain}
              alt=""
              className={`flag-image ${
                selectedCountry === "es" ? "selected" : ""
              }`}
              style={{
                width: "95px",
                height: "50px",
                borderRadius: "10%",
                boxShadow:
                  selectedCountry === "es"
                    ? "0 0 20px rgba(255, 193, 7, 1)"
                    : "none",
              }}
              onClick={() => handleCountryChange("es")}
            ></img>
          </div>
          <div>
            <img
              src={germany}
              alt=""
              className={`flag-image ${
                selectedCountry === "de" ? "selected" : ""
              }`}
              style={{
                width: "95px",
                height: "50px",
                borderRadius: "10%",
                boxShadow:
                  selectedCountry === "de"
                    ? "0 0 20px rgba(255, 193, 7, 1)"
                    : "none",
              }}
              onClick={() => handleCountryChange("de")}
            ></img>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "50px",
        }}
      >
        <h4>{t("areYouTourist")}</h4>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: "50px" }}>
          <div style={{ textAlign: "center" }}>
            <div className="btn-group">
              <button
                type="button"
                className={`btn btn-outline-warning ${
                  selectedOption === "Français" && "active"
                }`}
                onClick={() => handleOptionChange("Français")}
                style={{ width: "150px" }}
              >
                {t("frenchOption")}
              </button>
              <button
                type="button"
                className={`btn btn-outline-warning ${
                  selectedOption === "Etranger" && "active"
                }`}
                onClick={() => handleOptionChange("Etranger")}
                style={{ width: "150px" }}
              >
                {t("foreignerOption")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "200px" }}></div>
    </div>
  );
}

export default Languages;
