import React, { useEffect } from "react";

function GTranslateSettings() {
  useEffect(() => {
    // Vérifier si le script est déjà chargé
    if (!window.gtranslateSettings) {
      window.gtranslateSettings = {
        default_language: "fr",
        languages: ["fr", "en", "de", "es", "it", "zh-CN", "ar"],
        wrapper_selector: ".gtranslate_wrapper",
        flag_style: "3d",
      };

      const script = document.createElement("script");
      script.src = "//cdn.gtranslate.net/widgets/latest/flags.js";
      script.async = true;
      document.body.appendChild(script);

      // Nettoyer le script lors du démontage du composant
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  // Renvoyer la balise gtranslate_wrapper pour qu'elle soit rendue dans le composant
  return <div className="gtranslate_wrapper"></div>;
}

export default GTranslateSettings;
