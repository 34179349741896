// Import Axios library
import axios from "axios";

// Define your API base URL
//const apiUrl = "https://private.mybusiness-bnb.fr/api/";
//const apiUrl = "http://localhost:65447/api/";
const apiUrl = "https://mybusiness-bnb.fr/api/";

// Generic HTTP service class
class HttpService {
  static async get(url, params = {}) {
    try {
      const response = await axios.get(`${apiUrl}${url}`, { params });
      return response.data;
    } catch (error) {
      console.error("HTTP GET Error:", error);
      throw error;
    }
  }

  static async post(url, data = {}) {
    try {
      const response = await axios.post(`${apiUrl}/${url}`, data);
      return response.data;
    } catch (error) {
      console.error("HTTP POST Error:", error);
      throw error;
    }
  }

  static async put(url, data = {}) {
    try {
      const response = await axios.put(`${apiUrl}/${url}`, data);
      return response.data;
    } catch (error) {
      console.error("HTTP PUT Error:", error);
      throw error;
    }
  }

  // Add other HTTP methods (delete, patch, etc.) as needed
}

export default HttpService;
