import React, { useEffect, useState } from "react";
import ArrivalForm from "./Components/ArrivalForm";
import FrenchArrivalForm from "./Components/FrenchArrivalForm";
import Languages from "./Components/Languages";
import logo from "./Images/logo.png";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Welcom from "./Components/Welcom";
import IntroToMBApp from "./Components/IntroToMBApp";
import FormValidated from "./Components/FormValidated";
import DataContext from "./Context/DataContext";
import HttpService from "./Services/HttpService";

function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);
  const [selectedArrivalHour, setSelectedArrivalHour] = useState(null);
  const [selectedDepartureHour, setSelectedDepartureHour] = useState(null);
  const [selectedBeds, setSelectedBeds] = useState("");
  const [selectedOption, setSelectedOption] = useState("Français");
  const { t, i18n } = useTranslation();
  const [propertyData, setPropertyData] = useState(null);
  const [frenchArrivalFormData, setFrenchArrivalFormData] = useState(null);
  const [clientApp, setClientApp] = useState(false);
  const [hideFooter, sethideFooter] = useState(false);
  const [loading, setLoading] = useState(true);

  let pathSegments = "";
  let param1 = "";
  let param2 = "";

  if (window.location.hash === "") {
    pathSegments = window.location.pathname.split("/");
    param1 = pathSegments[pathSegments.length - 2]; // Avant-dernier segment
    param2 = pathSegments[pathSegments.length - 1]; // Dernier segment
  } else {
    pathSegments = window.location.hash.split("/");
    param1 = pathSegments[1];
    param2 = pathSegments[2];
  }

  useEffect(() => {
    setLoading(true);
    const updatedPropertyData = { ...propertyData, param1, param2 };
    setPropertyData(updatedPropertyData);

    HttpService.get(`Guest/details/${param1}/${param2}`)
      .then((data) => {
        if (data) {
          setClientApp(true);
          sethideFooter(true);
          setCurrentStep(3);
        }
      })
      .catch((error) => {
        console.error("GET Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [param1, param2]);

  const handleContinue = () => {
    window.scrollTo(0, 0);
    if (currentStep === 2) {
      if (!selectedArrivalHour || !selectedDepartureHour) {
        setError(t("selectBothHours"));
        return;
      }
      if (!selectedBeds) {
        setError(t("selectBedsError"));
        return;
      }
    }

    setError(null);

    if (currentStep === 4) {
      if (selectedOption == "Français") {
        if (
          frenchArrivalFormData == null ||
          !frenchArrivalFormData.email ||
          !frenchArrivalFormData.firstName ||
          !frenchArrivalFormData.lastName ||
          !frenchArrivalFormData.phone
        ) {
          setError(t("fillAllFields"));
          return;
        }
      } else {
        if (
          frenchArrivalFormData == null ||
          !frenchArrivalFormData.email ||
          !frenchArrivalFormData.firstName ||
          !frenchArrivalFormData.lastName ||
          !frenchArrivalFormData.birthDate ||
          !frenchArrivalFormData.placeOfBirth ||
          !frenchArrivalFormData.nationality ||
          !frenchArrivalFormData.country ||
          !frenchArrivalFormData.streetAddress ||
          !frenchArrivalFormData.postalCode ||
          !frenchArrivalFormData.city ||
          !frenchArrivalFormData.phone
        ) {
          setError(t("fillAllFields"));
          return;
        }
      }

      if (frenchArrivalFormData.email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(frenchArrivalFormData.email)) {
          setError(t("emailFormatIncorrect"));
          return; // Ne pas mettre à jour le state si l'e-mail est invalide
        }
      }

      frenchArrivalFormData.param1 = param1;
      frenchArrivalFormData.firstNight = propertyData.firstNight;
      frenchArrivalFormData.lastNight = propertyData.lastNight;
      HttpService.post("Guest", frenchArrivalFormData);

      // Move to the next step
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }

    if (currentStep === 3 && !propertyData.guestFormsActivationg) {
      console.log("no forms");
      const data = {};
      data.firstName = propertyData.firstName;
      data.lastName = propertyData.lastName;
      data.phone = propertyData.phone;
      data.email = propertyData.email;
      data.param1 = param1;
      data.bookingId = param2;
      data.arrivalHour = propertyData.arrivalHour;
      data.departureHour = propertyData.departureHour;
      data.roomId = propertyData.roomId;
      data.propId = propertyData.propId;
      data.firstNight = propertyData.firstNight;
      data.lastNight = propertyData.lastNight;
      data.beds = propertyData.beds;
      console.log(propertyData);
      console.log(data);
      HttpService.post("Guest", data);
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleGoBack = () => {
    window.scrollTo(0, 0);
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
    setError(null); // Clear error when going back
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // Définissez les étapes et les composants correspondants
  const steps = {
    1: (
      <Languages
        onContinue={handleContinue}
        onOptionChange={handleOptionChange}
      />
    ),
    2: <Welcom onContinue={handleContinue} />,
    3: <IntroToMBApp onContinue={handleContinue} clientApp={clientApp} />,
    4:
      selectedOption === "Français" ? (
        <FrenchArrivalForm onContinue={handleContinue} />
      ) : (
        <ArrivalForm onContinue={handleContinue} />
      ),
    5: <FormValidated onContinue={handleContinue} />,
  };

  return (
    <DataContext.Provider value={{ propertyData, setPropertyData }}>
      <I18nextProvider i18n={i18n}>
        {loading ? (
          <div className="overlay">
            <div className="overlay-content">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-dark text-white">
            {/* Header */}
            <header className="d-flex flex-column align-items-center justify-content-center p-3 bg-dark text-white">
              <img src={logo} alt="logo" style={{ height: "70px" }} />
              <hr
                style={{
                  width: "100%",
                  border: "1px solid white",
                  margin: "10px 0",
                }}
              />
            </header>
            {/* Contenu central */}
            <main className="mt-5 bg-dark text-white">
              {React.cloneElement(steps[currentStep], {
                onContinue: handleContinue,
                selectedArrivalHour,
                setSelectedArrivalHour,
                selectedDepartureHour,
                setSelectedDepartureHour,
                setSelectedBeds,
                selectedBeds,
                error,
                t: i18n.t,
                frenchArrivalFormData,
                setFrenchArrivalFormData,
              })}
            </main>

            <footer className="align-items-center justify-content-center p-3 bg-dark text-white">
              <hr style={{ width: "100%", border: "1px solid white" }} />
              {!hideFooter && currentStep !== 1 && currentStep !== 5 && (
                <button
                  className="btn btn-secondary"
                  style={{ width: "150px", marginRight: "10px" }}
                  onClick={handleGoBack}
                >
                  <FaArrowLeft />
                </button>
              )}
              {/* Bouton avec la flèche droite */}
              {!hideFooter && currentStep !== 5 && (
                <button
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                  onClick={handleContinue}
                >
                  <FaArrowRight />
                </button>
              )}
            </footer>
          </div>
        )}
      </I18nextProvider>
    </DataContext.Provider>
  );
}

export default App;
