import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useDataContext } from "../Context/DataContext";

function FrenchArrivalForm({
  onContinue,
  frenchArrivalFormData,
  setFrenchArrivalFormData,
  error,
}) {
  const { t, i18n } = useTranslation();
  const { propertyData } = useDataContext();

  const [formData, setFormData] = useState({
    lastName: propertyData.lastName,
    firstName: propertyData.firstName,
    phone: propertyData.phone,
    email: "",
  });

  useEffect(() => {
    if (error) {
      const formError = document.getElementById("formError");
      if (formError) {
        formError.scrollIntoView({ behavior: "smooth" });
      }
    }

    setFrenchArrivalFormData((prevData) => ({
      ...prevData,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
    }));
  }, [error]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setFrenchArrivalFormData((prevData) => ({
      ...prevData,
      [id]: value,
      arrivalHour: propertyData.arrivalHour,
      departureHour: propertyData.departureHour,
      bookingId: propertyData.param2,
      propId: propertyData.propId,
      roomId: propertyData.roomId,
      beds: propertyData.beds,
    }));
  };

  const handleContinue = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4>{t("requiredInformation")}</h4>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ color: "#FFC107", textAlign: "center", padding: "20px" }}>
          {t("informationTransmission")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form>
          <div className="form-group">
            <h4 style={{ color: "#FF1E34" }}>{t("personalInfo")}</h4>
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                {t("lastName")}
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                required
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                {t("firstName")}
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group">
            <h4 style={{ color: "#FF1E34" }}>{t("communicationMethod")}</h4>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                {t("phone")}
              </label>
              <div className="input-group">
                <span className="input-group-text form-icon">
                  <FaPhoneAlt />
                </span>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                {t("email")}
              </label>
              <div className="input-group">
                <span className="input-group-text form-icon">
                  <MdAlternateEmail />
                </span>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-text">{t("invalidEmail")}</div>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="mb-3" id="formError"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FrenchArrivalForm;
