import React, { useEffect, useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import HttpService from "../Services/HttpService";
import { useDataContext } from "../Context/DataContext";
import IntroToMBApp from "./IntroToMBApp";
import { decode } from "html-entities";

function Welcom({
  onContinue,
  selectedArrivalHour,
  setSelectedArrivalHour,
  selectedDepartureHour,
  setSelectedDepartureHour,
  selectedBeds,
  setSelectedBeds,
  error,
}) {
  const { t, i18n } = useTranslation();
  const { propertyData, setPropertyData } = useDataContext();
  const [loading, setLoading] = useState(true);
  const [arrivalHoursOptions, setArrivalHoursOptions] = useState([]);
  const [departurelHoursOptions, setDepartureHoursOptions] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [isBookingFound, setIsBookingFound] = useState(true);

  // const [selectedBeds, setSelectedBeds] = useState(""); // État pour le nombre de couchages sélectionné
  const [bedsError, setBedsError] = useState("");
  const bedOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const handleArrivalHourSelect = (hour, e) => {
    const newData = { ...propertyData, arrivalHour: hour };
    setPropertyData(newData);
    e.preventDefault();
    setSelectedArrivalHour(hour);
  };

  const handleDepartureHourSelect = (hour, e) => {
    const newData = { ...propertyData, departureHour: hour };
    setPropertyData(newData);
    e.preventDefault();
    setSelectedDepartureHour(hour);
  };

  const handleBedsSelect = (beds, e) => {
    const newData = { ...propertyData, beds: beds };
    setPropertyData(newData);
    e.preventDefault();
    setSelectedBeds(beds);
    setBedsError(""); // Réinitialiser l'erreur de sélection de couchages
  };

  const highlightTextInRed = (inputString) => {
    const targetText = "MyBusiness-bnb";
    if (inputString.includes(targetText)) {
      const styledString = inputString.replace(
        new RegExp(targetText, "g"),
        `<span style="color: #FF1654;">${targetText}</span>`
      );
      return styledString;
    } else {
      return inputString;
    }
  };

  useEffect(() => {
    const mBKEY = propertyData.param1;
    const bookId = propertyData.param2;

    setLoading(true);

    if (propertyData.propName == null) {
      HttpService.get(`GuestForm/${mBKEY}/${bookId}`)
        .then((data) => {
          if (Object.keys(data).length == 0) {
            setIsBookingFound(false);
            return;
          }

          setPropertyData({ ...propertyData, ...data });

          const nouvellesOptions = [];
          for (let i = data.arrivalTimeFrom; i <= data.arrivalTimeTo; i++) {
            if (i < data.arrivalTimeTo) {
              for (let j = 0; j < 60; j += 30) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                const minute = j === 0 ? "00" : `${j}`;
                nouvellesOptions.push(`${hour}:${minute}`);
              }
            } else {
              nouvellesOptions.push(`${data.arrivalTimeTo}:00`);
            }
          }

          setArrivalHoursOptions(nouvellesOptions);

          const nouvellesOptionsD = [];
          for (let i = data.departureTimeFrom; i <= data.departureTimeTo; i++) {
            for (let j = 0; j < 60; j += 30) {
              if (i === data.departureTimeTo && j === 30) {
                break; // Arrête la boucle dès qu'on atteint l'heure de fin
              }

              const hour = i < 10 ? `${i}` : `${i}`;
              const minute = j === 0 ? "00" : `${j}`;
              nouvellesOptionsD.push(`${hour}:${minute}`);
            }
          }

          setDepartureHoursOptions(nouvellesOptionsD);
        })
        .catch((error) => {
          console.error("GET Error:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when the request is completed
        });
    } else {
      setLoading(false);
    }

    // Scroll to the dropdown lists when an error is present
    if (error) {
      const dropdownLists = document.getElementById("dropdown-lists");
      if (dropdownLists) {
        dropdownLists.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [error]);

  if (redirect) {
    return <IntroToMBApp clientApp="true" />;
  }

  const formatDatabaseDate = (dateFromDatabase, t) => {
    const dateObject = new Date(dateFromDatabase);
    const day = dateObject.getDate();
    const month = t(`months.${dateObject.getMonth() + 1}`);
    const year = dateObject.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let formHasError = false;
    let formBedsHasError = false;

    if (!selectedArrivalHour || !selectedDepartureHour) {
      formHasError = true;
    }

    if (!selectedBeds) {
      setBedsError(t("selectBedsError"));
      formBedsHasError = true;
    }

    if (formHasError) {
      const dropdownLists = document.getElementById("dropdown-lists");
      if (dropdownLists) {
        dropdownLists.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    if (formBedsHasError) {
      const dropdownLists = document.getElementById("ddSelectBeds");
      if (dropdownLists) {
        dropdownLists.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    onContinue();
  };

  return (
    <div className="container-fluid mt-4">
      {!isBookingFound && (
        <div className="overlay">
          <div className="overlay-content">
            <p>{"Réservation non trouvée."}</p>
            <button
              style={{
                backgroundColor: "#2980B9",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            ></button>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <h4
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: highlightTextInRed(
                      t("welcome").replace("\u200B", decode(propertyData.name))
                    ),
                  }}
                />
              )}
            </div>
            <div className="mb-3">
              <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: highlightTextInRed(t("conciergeInfo")),
                }}
              />
              <br />
              <div style={{ textAlign: "center" }}>{t("conciergeInfo2")}</div>
              <br />
              <div style={{ color: "#FFC107", textAlign: "center" }}>
                {t("conciergeInfo3")}
              </div>
            </div>
            <div className="mb-3">
              <div>
                <b>{t("houseName")}</b>
                <br />{" "}
                {propertyData ? decode(propertyData.propName) : "Loading..."}
              </div>
              <br />
              <div>
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  propertyData &&
                  propertyData.image && (
                    <img
                      src={propertyData.image}
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "cover",
                      }}
                      alt={propertyData.propName || "Property Image"}
                    />
                  )
                )}
              </div>
              <br />
              <div>
                <b>{t("reservationDates")}</b>
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IoCalendarOutline style={{ color: "red" }} />
                  <span style={{ margin: "0 5px" }}>
                    {loading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <div>
                        {formatDatabaseDate(propertyData.firstNight, t)}
                      </div>
                    )}
                  </span>
                  <span style={{ margin: "0 5px" }}>
                    {loading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <div>
                        {t("to")}{" "}
                        {formatDatabaseDate(propertyData.lastNight, t)}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <br />
              <div className="mb-3" id="dropdown-lists">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <label htmlFor="nom" className="form-label">
                  <b>{t("specifyArrivalDeparture")}</b>
                </label>
                <br />
                <br />
                <div className="d-flex">
                  {loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <div className="btn-group dropup me-2">
                      <button
                        className="btn btn-warning dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedArrivalHour
                          ? selectedArrivalHour
                          : t("arrivalHour")}
                      </button>
                      <ul className="dropdown-menu">
                        {arrivalHoursOptions.map((heure, index) => (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => handleArrivalHourSelect(heure, e)}
                            >
                              {heure}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <div className="btn-group dropup me-2">
                      <button
                        className="btn btn-warning dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedDepartureHour
                          ? selectedDepartureHour
                          : t("departureHour")}
                      </button>
                      <ul className="dropdown-menu">
                        {departurelHoursOptions.map((heure, index) => (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) =>
                                handleDepartureHourSelect(heure, e)
                              }
                            >
                              {heure}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <br />
                <label htmlFor="beds" className="form-label">
                  <b>{t("numberOfBeds")}</b>
                </label>
                <br />
                <br />
                <div className="btn-group dropup me-2">
                  <button
                    className="btn btn-warning dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedBeds ? selectedBeds : t("selectBeds")}
                  </button>
                  <ul className="dropdown-menu">
                    {bedOptions.map((beds, index) => (
                      <li key={index}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => handleBedsSelect(beds, e)}
                        >
                          {beds}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {bedsError && (
                  <div className="alert alert-danger mt-2" role="alert">
                    {bedsError}
                  </div>
                )}
              </div>
              <div id="ddSelectBeds"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Welcom;
