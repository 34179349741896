import { createContext, useContext } from "react";

// Créez un contexte avec une valeur par défaut
const DataContext = createContext({
  propertyData: null,
  setPropertyData: () => {},
});

// Créez un hook personnalisé pour utiliser le contexte
export const useDataContext = () => useContext(DataContext);

export default DataContext;
