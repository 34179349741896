import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDataContext } from "../Context/DataContext";
import googleMaps from "../Images/google-maps.png";
import googleMapsApp from "../Images/google-maps-App.png";
import appleMapsApp from "../Images/appleMaps.png";
import caution from "../Images/caution.png";
import arrowleft from "../Images/arrowleft.png";

function ClientAppAddress({ guideTypeTitle, onBack }) {
  const { t, i18n } = useTranslation();
  const { propertyData, setPropertyData } = useDataContext();
  const [linkCopied, setLinkCopied] = useState("Copier l'adresse");
  const [linkCopiedColor, setLinkCopiedColor] = useState(false);

  if (propertyData.data.apartmentDetails == null) {
    return (
      <div className="mt-4">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {" "}
          {/* Aligner les éléments vers la gauche */}
          <div
            onClick={onBack}
            style={{ alignSelf: "flex-start", paddingTop: "5px" }}
          >
            <img
              src={arrowleft}
              alt="Back"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
          <h4
            style={{
              color: "white",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {guideTypeTitle}
          </h4>
        </div>
      </div>
    );
  }

  const linkToCopy = propertyData.data.apartmentDetails.address;

  const getMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(userAgent) && /Mobile/.test(userAgent);

    if (isIOS) {
      return "ios";
    } else if (isAndroid) {
      return "android";
    } else {
      return "others";
    }
  };

  const openInGoogleMapsBrowser = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
      linkToCopy
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  const openInGoogleMapsApplication = () => {
    const mapUrl = `geo:0,0?q=${encodeURIComponent(linkToCopy)}`;
    window.open(mapUrl, "_blank");
  };

  const openInApplePlansApplication = () => {
    const mapUrl = `maps:q=${encodeURIComponent(linkToCopy)}`;
    window.open(mapUrl, "_blank");
  };

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = linkToCopy;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      setLinkCopied("Adresse copiée !");
      setLinkCopiedColor(true);

      setTimeout(() => {
        setLinkCopied("Copier l'adresse");
        setLinkCopiedColor(false);
      }, 2000);
    } catch (err) {
      console.error("Erreur lors de la copie dans le presse-papiers : ", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {" "}
        {/* Aligner les éléments vers la gauche */}
        <div
          onClick={onBack}
          style={{ alignSelf: "flex-start", paddingTop: "5px" }}
        >
          <img
            src={arrowleft}
            alt="Back"
            style={{ width: "32px", height: "32px" }}
          />
        </div>
        <h4 style={{ color: "white", marginLeft: "auto", marginRight: "auto" }}>
          {guideTypeTitle}
        </h4>
      </div>
      <div className="mb-3">
        <br />
        <div
          style={{
            background: "#515151",
            padding: "10px",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          {propertyData.data.apartmentDetails.address}
        </div>
        {propertyData.data.apartmentDetails.addressNotes && (
          <div>
            <br />
            <div
              style={{
                color: "#FFC107",
                textAlign: "center",
                background: "#0f0b0b",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <img
                src={caution}
                alt="Google Maps"
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
              />
              {propertyData.data.apartmentDetails.addressNotes}
            </div>
          </div>
        )}
      </div>
      <div className="mb-3">
        <br />
        <div
          style={{
            display: "flex",
            cursor: "pointer",
          }}
          onClick={copyToClipboard}
        >
          <svg
            width="32px"
            height="32px"
            viewBox="-0.25 -0.25 25.50 25.50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="SVGRepo_bgCarrier"
              strokeWidth="0"
              transform="translate(0,0), scale(1)"
            >
              <rect
                x="-0.25"
                y="-0.25"
                width="25.50"
                height="25.50"
                rx="6.12"
                fill="#2e9e6a"
                strokeWidth="0"
              ></rect>
            </g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#CCCCCC"
              strokeWidth="0.9"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.676 14.248C17.676 15.8651 16.3651 17.176 14.748 17.176H7.428C5.81091 17.176 4.5 15.8651 4.5 14.248V6.928C4.5 5.31091 5.81091 4 7.428 4H14.748C16.3651 4 17.676 5.31091 17.676 6.928V14.248Z"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M10.252 20H17.572C19.1891 20 20.5 18.689 20.5 17.072V9.75195"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>
          <div
            style={{
              marginLeft: "10px",
              color: linkCopiedColor ? "red" : "white",
            }}
          >
            {linkCopied}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div
          style={{
            display: "flex",
          }}
          onClick={openInGoogleMapsBrowser}
        >
          <img
            src={googleMaps}
            alt="Google Maps"
            style={{ width: "32px", height: "32px" }}
          />
          <span style={{ marginLeft: "10px" }}>
            Google Maps dans le navigateur
          </span>
        </div>
      </div>
      {getMobileOS() == "android" && (
        <div>
          <div className="mb-3">
            <div
              style={{
                display: "flex",
              }}
              onClick={openInGoogleMapsApplication}
            >
              <img
                src={googleMapsApp}
                alt="Google Maps"
                style={{ width: "32px", height: "32px" }}
              />
              <span style={{ marginLeft: "10px" }}>
                Application Google Maps
              </span>
            </div>
          </div>
        </div>
      )}
      {getMobileOS() == "ios" && (
        <div>
          <div className="mb-3">
            <div
              style={{
                display: "flex",
              }}
              onClick={openInApplePlansApplication}
            >
              <img
                src={appleMapsApp}
                alt="Apple Plans"
                style={{ width: "32px", height: "32px" }}
              />
              <span style={{ marginLeft: "10px" }}>
                Application Apple Plans
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientAppAddress;
