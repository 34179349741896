import React from "react";
import { useTranslation } from "react-i18next";
import { IoCheckmarkSharp } from "react-icons/io5";

const styles = {
  borderRadius: "50%",
  width: "100px",
  height: "100px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  backgroundColor: "#29BB66",
};

function FormValidated() {
  const { t, i18n } = useTranslation();

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4>{t("preRegistrationSuccess")}</h4>
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <div style={{ ...styles, marginBottom: "30px" }}>
          <IoCheckmarkSharp style={{ fontSize: "60px" }} />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: t("registrationComplete"),
          }}
        ></div>
      </div>
    </div>
  );
}

export default FormValidated;
