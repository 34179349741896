import React from "react";
import { useTranslation } from "react-i18next";
import { useDataContext } from "../Context/DataContext";
import person from "../Images/person.png";
import email from "../Images/email.png";
import phone from "../Images/phone.png";
import arrowleft from "../Images/arrowleft.png";
import whatsapp from "../Images/whatsapp.svg";

function ClientAppContact({ guideTypeTitle, onBack }) {
  const { t, i18n } = useTranslation();
  const { propertyData, setPropertyData } = useDataContext();

  if (propertyData.data.apartmentDetails == null) {
    return (
      <div className="mt-4">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {" "}
          {/* Aligner les éléments vers la gauche */}
          <div
            onClick={onBack}
            style={{ alignSelf: "flex-start", paddingTop: "5px" }}
          >
            <img
              src={arrowleft}
              alt="Back"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
          <h4
            style={{
              color: "white",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {guideTypeTitle}
          </h4>
        </div>
      </div>
    );
  }

  const handlePhoneCall = () => {
    window.location.href = `tel:${propertyData.data.apartmentDetails.personToContactPhone}`;
  };

  const handleWhatsappCall = () => {
    window.location.href = `https://wa.me/${propertyData.data.apartmentDetails.personToContactPhone}`;
  };

  const handleEmail = () => {
    window.location.href = `mailto:${propertyData.data.apartmentDetails.personToContactEmail}`;
  };

  return (
    <div className="mt-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {" "}
        {/* Aligner les éléments vers la gauche */}
        <div
          onClick={onBack}
          style={{ alignSelf: "flex-start", paddingTop: "5px" }}
        >
          <img
            src={arrowleft}
            alt="Back"
            style={{ width: "32px", height: "32px" }}
          />
        </div>
        <h4 style={{ color: "white", marginLeft: "auto", marginRight: "auto" }}>
          {guideTypeTitle}
        </h4>
      </div>
      <br />
      <div>
        <div className="mb-3">
          <div
            style={{
              background: "#515151",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <img
              src={person}
              alt=""
              style={{ width: "32px", height: "32px" }}
            />
            <span style={{ marginLeft: "10px" }}>
              {propertyData.data.apartmentDetails.personToContact}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-3" onClick={handleEmail}>
          <div
            style={{
              background: "#293742",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
              boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.5)",
            }}
          >
            <img src={email} alt="" style={{ width: "32px", height: "32px" }} />
            <span style={{ marginLeft: "10px" }}>
              <a href="#" style={{ color: "#ffffff", textDecoration: "none" }}>
                {propertyData.data.apartmentDetails.personToContactEmail}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-3" onClick={handlePhoneCall}>
          <div
            style={{
              background: "#2977AA",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
              boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.5)",
            }}
          >
            <img src={phone} alt="" style={{ width: "32px", height: "32px" }} />
            <span style={{ marginLeft: "10px" }}>
              {propertyData.data.apartmentDetails.personToContactPhone}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-3" onClick={handleWhatsappCall}>
          <div
            style={{
              background: "#2977AA",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
              boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.5)",
            }}
          >
            <img
              src={whatsapp}
              alt=""
              style={{ width: "32px", height: "32px" }}
            />
            <span style={{ marginLeft: "10px" }}>
              {propertyData.data.apartmentDetails.personToContactPhone}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAppContact;
